import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';

import MovieCaruselBlockItem from '@/components/MovieCaruselBlockItem';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { mqMax } from '@/styles/breakpoints';

import { MoviesCarusel } from '../../payload-types';
import { ArrowIcon } from '../components/ArrowIcon';
import { arrowCursorDataURI } from '../../components/ThemedCarousel/utils';


interface ArrowProps {
  color?: string;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  min-height: 600px;


  .slick-dots {
    bottom: 50px;
    right: 30px;
    text-align: right;
    width: 200px;

    li {
      margin: 0;
    }

    li button:before {
      border: 1px solid white;
      border-radius: 50%;
      opacity: 1;
      line-height: 10px;
      font-size: 10px;
      height: 10px;
      width: 10px;
      box-sizing: border-box;
      content: '';
    }

    li.slick-active button:before {
      content: '';
      background-color: ${({ theme }) => theme.palette.primary.main};
      border: none;
    }

    .custom-slider {
      height: 100%;
    }
  }
`;

const getArrowStyles = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 140px;
  height: 80%;
  overflow: hidden;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  ${mqMax[4]} {
    display: none;
  }
`;

export const StyledNextArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  right: 0;
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};
  :hover {
    cursor: url(${(props) => arrowCursorDataURI(props.color, 'right')}) 64 32, pointer;
  }
`;

export const StyledPrevArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};
  left: 0;
  :hover {
    cursor: url(${(props) => arrowCursorDataURI(props.color, 'left')}) -64 32, pointer;
  }
`;
function NextArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick, color } = props;

  return (
    <StyledNextArrow onClick={onClick} color={color}>
      {/* <ArrowIcon hoverColor="#FFFFFF" color={color} direction="right" size={80} stroke={2} /> */}
    </StyledNextArrow>
  );
}

function PrevArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick, color } = props;

  return (
    <StyledPrevArrow onClick={onClick} color={color}>
      {/* <ArrowIcon color={color} hoverColor="#FFFFFF" direction="left" size={80} stroke={2} /> */}
    </StyledPrevArrow>
  );
}

export const MoviesCaruselBlock: FunctionComponent<MoviesCarusel & { authUser?: IAuthUser; clientIp?: string }> = (
  props
) => {
  const { id, authUser, arrowColor, movie, clientIp } = props;

  return (
    <Wrapper key={id}>
      <Slider
        infinite={true}
        dots={true}
        className="custom-slider"
        arrows={true}
        nextArrow={<NextArrow color={arrowColor as string} />}
        prevArrow={<PrevArrow color={arrowColor as string} />}
        autoplay={true}
        autoplaySpeed={7000}
        pauseOnHover={false}
        speed={1000}
      >
        {movie?.map((movie) => {
          return <MovieCaruselBlockItem key={movie.id} movie={movie} authUser={authUser} clientIp={clientIp} />;
        })}
      </Slider>
    </Wrapper>
  );
};

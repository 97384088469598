import { Box, CircularProgress } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { FC, useEffect, useMemo, useRef } from 'react';

import BookmarksCollection from '@/components/BoomarksCollection';
import { ThemedCollection } from '@/components/ThemedCollection/ThemedCollection';
import { useAccountState } from '@/context/accountContext';
import { useBookmarks } from '@/context/bookmarks/bookmarks.provider';
import { useCollections } from '@/hooks/useCollections';

const Collection = dynamic(import('../components/Collection'));

export interface MovieCollectionsProps {
  hideFromLoggedOutUser?: boolean;
  previewCollectionId?: string;
  landingPageCollectionId?: string;
  clientIp: string;
  hasSubscriptionForThisPage?: boolean;
}

const MovieCollections: FC<MovieCollectionsProps> = ({
  hideFromLoggedOutUser = true,
  previewCollectionId = '',
  landingPageCollectionId = '',
  clientIp,
  hasSubscriptionForThisPage,
}) => {
  const account = useAccountState();

  const hideAllCollections = useMemo(
    () => (hideFromLoggedOutUser && !account.auth.token) || (hideFromLoggedOutUser && !hasSubscriptionForThisPage),
    [hideFromLoggedOutUser, account.auth.token, hasSubscriptionForThisPage]
  );

  const {
    pageInfo,
    fetchMoreCollection,
    previewCollection,
    collectionList,
    areCollectionsLoading,
    collectionsStylesConfig,
  } = useCollections({
    landingPageCollectionId,
    previewCollectionId,
    hideFromLoggedOutUser: hideAllCollections,
    clientIp,
    token: account.auth.token ?? '',
  });

  const observerTarget = useRef(null);
  const {bookmarksCollection} = useBookmarks();

  useEffect(() => {
    const currentTarget = observerTarget.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreCollection();
        }
      },
      { threshold: 1 }
    );

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [fetchMoreCollection, observerTarget]);

  return (
    <>
      <div>
        <Box>
          {hideAllCollections
            ? previewCollection?.map((edge) => {
                return (
                  edge &&
                  'magineId' in edge && (
                    <Collection
                      magineId={edge.magineId}
                      title={edge.title}
                      description={edge.description}
                      image={edge.image}
                      linksEdges={'links' in edge ? edge.links.edges : null}
                      viewableEdges={'viewables' in edge ? edge.viewables.edges : null}
                      key={edge.magineId}
                    />
                  )
                );
              })
            : collectionList?.map((edge) => {
                const description = edge?.node?.description;
                const stylesConfig = collectionsStylesConfig?.find((config) => config.magineId === edge?.node.magineId);
                if (edge?.node.__typename === 'BookmarksCollection') {
                  return (
                    <BookmarksCollection
                      title={edge.node.title}
                      magineId={edge.node.magineId}
                      viewableEdges={bookmarksCollection}
                      key={edge.node.magineId}
                    />
                  );
                } else
                  return (
                    edge?.node &&
                    'magineId' in edge.node &&
                    (stylesConfig ? (
                      <ThemedCollection
                        magineId={edge.node.magineId}
                        magineTitle={edge.node.title}
                        magineDescription={!description?.startsWith('#') ? description : undefined}
                        magineImage={edge.node.image}
                        linksEdges={'links' in edge.node ? edge.node.links.edges : null}
                        viewableEdges={'viewables' in edge.node ? edge.node.viewables.edges : null}
                        key={edge.node.magineId}
                        stylesConfig={stylesConfig}
                      />
                    ) : (
                      <Collection
                        magineId={edge.node.magineId}
                        title={edge.node.title}
                        description={!description?.startsWith('#') ? description : undefined}
                        image={edge.node.image}
                        linksEdges={'links' in edge.node ? edge.node.links.edges : null}
                        viewableEdges={'viewables' in edge.node ? edge.node.viewables.edges : null}
                        key={edge.node.magineId}
                      />
                    ))
                  );
              })}

          {}
          {areCollectionsLoading && (
            <Box textAlign="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          {pageInfo?.hasNextPage && !hideAllCollections && <div ref={observerTarget} />}
        </Box>
      </div>
    </>
  );
};

export default MovieCollections;

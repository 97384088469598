import { useCallback, useEffect, useState } from 'react';

import { getCollectionsStylesConfig } from '@/endpoints/endpoints';
import {
  GetBasicCollectionQuery,
  GetBasicCollectionsQuery,
  useGetBasicCollectionQuery,
  useGetBasicCollectionsQuery,
} from '@/graphql/queries/__generated__/collection.generated';
import { CollectionsStylesConfig } from '@/payload-types';

interface UseCollectionProps {
  landingPageCollectionId: string;
  previewCollectionId: string;
  hideFromLoggedOutUser: boolean;
  clientIp: string;
  token: string;
}

type QueryEdgesType = NonNullable<GetBasicCollectionsQuery['viewer']['view']>['blocks']['edges'];
type PreviewQueryEdgesType = GetBasicCollectionQuery['viewer']['block'];

type PageInfo = {
  __typename: 'PageInfo';
  hasNextPage: boolean;
  endCursor?: string | null | undefined;
};

const useCollections = ({
  landingPageCollectionId,
  previewCollectionId,
  hideFromLoggedOutUser,
  clientIp,
  token,
}: UseCollectionProps) => {
  const {
    fetchMore,
    data: landingPageCollectionsData,
    loading,
  } = useGetBasicCollectionsQuery({
    variables: { id: landingPageCollectionId, first: 10 },
    context: { authToken: token, clientIp },
    skip: hideFromLoggedOutUser,
  });

  const { data: previewCollectionData, loading: previewCollectionDataLoading } = useGetBasicCollectionQuery({
    variables: { id: previewCollectionId, first: 10 },
    context: { authToken: token, clientIp },
    skip: !hideFromLoggedOutUser,
  });

  const [collectionList, setCollectionList] = useState<QueryEdgesType>(
    landingPageCollectionsData?.viewer?.view?.blocks.edges ?? []
  );
  const [previewCollection, setPreviewCollection] = useState<PreviewQueryEdgesType[]>(
    [previewCollectionData?.viewer?.block].filter(Boolean)
  );

  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);

  const [collectionsStylesConfig, setCollectionsStylesConfig] = useState<CollectionsStylesConfig[] | null>(null);

  const fetchCollectionsStylesConfig = useCallback(() => {
    getCollectionsStylesConfig().then((response) => {
      setCollectionsStylesConfig(response.data.docs);
    });
  }, []);

  useEffect(() => {
    fetchCollectionsStylesConfig();
  }, [fetchCollectionsStylesConfig]);

  useEffect(() => {
    if (!loading && landingPageCollectionsData?.viewer?.view?.blocks.edges && !hideFromLoggedOutUser) {
      setCollectionList(landingPageCollectionsData?.viewer?.view?.blocks.edges);
      setPageInfo(landingPageCollectionsData?.viewer?.view?.blocks.pageInfo);
    } else if (!previewCollectionDataLoading && previewCollectionData?.viewer?.block && hideFromLoggedOutUser) {
      const previewBlock = previewCollectionData?.viewer?.block;

      setPreviewCollection([previewBlock] as PreviewQueryEdgesType[]);
      if ('viewables' in previewBlock) {
        setPageInfo(previewBlock?.viewables?.pageInfo);
      }
    }
  }, [loading, previewCollectionDataLoading, landingPageCollectionsData, previewCollectionData, hideFromLoggedOutUser]);

  const fetchMoreCollection = useCallback(async () => {
    setAreCollectionsLoading(true);
    const { data } = await fetchMore({
      variables: { after: pageInfo?.endCursor, first: 5 },
    });
    setAreCollectionsLoading(false);

    setPageInfo(data?.viewer?.view?.blocks?.pageInfo);
    setCollectionList((prevState: QueryEdgesType) => {
      const newEdges = data.viewer.view?.blocks.edges;
      if (Array.isArray(prevState) && Array.isArray(newEdges)) {
        return [...prevState, ...newEdges];
      }
      return prevState;
    });
  }, [fetchMore, pageInfo?.endCursor]);

  return {
    collectionList,
    previewCollection,
    pageInfo,
    fetchMoreCollection,
    areCollectionsLoading,
    collectionsStylesConfig,
  };
};

export { useCollections };
